import React from "react";

import { graphql } from "gatsby";

import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { Calendly } from "@/components/Calendly";

import { useSiteMetadata } from "@/hooks";

const CalendlyPage: React.FC<Props> = () => {

  return (
    <Layout>
      <Sidebar />
      <Page title="Schedule an intro">
        <Calendly account="henry-christopher" eventName="introductory-call" />
      </Page>
    </Layout>
  );
};

export const Head: React.FC<Props> = () => {
  const { title } = useSiteMetadata();

  return (
    <Meta
      title={`Schedule with me - ${title}`}
      description="Schedule with me"
    />
  );
};

export default CalendlyPage;
